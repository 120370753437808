<template>
  <ClientOnly>
    <div class="w-full">
      <div class="relative items-center w-full">
        <label :for="id"
               :class="{ required: required }"
        >{{ label }}</label>
        <input :id="id"
               :required="required"
               :disabled="state === 'loading'"
               :value="modelValue"
               :name="id"
               :type="inputType"
               class="w-full py-4 px-6 text-base font-medium text-white placeholder-gray-200 bg-gray-300 border-none rounded-full focus:outline-none focus:ring-blue-300 focus:ring-2 focus:ring-offset-black disabled:cursor-wait"
               :placeholder="label"
               :class="state === 'error' ? 'ring ring-red-200' : 'ring-0'"
               @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        >
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { FormStateType } from '../../../types/form'

interface FormInputProps {
  state: FormStateType
  id: string
  label: string
  inputType: string
  required: boolean
  modelValue: string
  type?: string
}

defineProps<FormInputProps>()

const emit = defineEmits<{
  (event: 'update:modelValue', payload: string): void
}>()
</script>

<style scoped lang="scss">
.required::after {
  content: ' *';
}
</style>
